import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ExternalSvg from "../components/external"

class Works extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const images = data.images.edges.map(({ node }) => {
      return [node.name, node.childImageSharp.fluid]
    }).reduce((result, current) => {
      result[current[0]] = current[1]
      return result
    }, {})
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Works - Kuokoa Studio" />
        <article className="works">
          <h1>Recent Work</h1>
          {data.works.edges.map(({ node }) => {
            const d = node.childMarkdownRemark.frontmatter
            const tags = d.tags.split(",");
            return d.display && (
              <section className={"project " + d.branch} key={node.id}>
                { images.hasOwnProperty(node.name) && (
                  <aside><Img fluid={images[node.name]} objectPosition="top center" /></aside>
                ) }
                <section className="content">
                  <h2>
                    { !!d.url ? (
                      <a href={d.url}>{d.project} <ExternalSvg /></a>
                    ) : d.project }
                  </h2>
                  <h3>{ d.tagline }</h3>
                  <hr />
                  <section className="description" dangerouslySetInnerHTML={{
                    __html: node.childMarkdownRemark.html,
                  }} />
                  <ul>
                  <li className={d.branch}>Kuokoa {d.branch[0].toUpperCase() + d.branch.slice(1)}</li>
                  { !!d.client && (<li className="client">{d.client}</li>) }
                  {tags.map(tag => {
                    return (
                      <li>{ tag }</li>
                    )
                  })}
                  </ul>
                </section>
              </section>
            )
          })}
        </article>
      </Layout>
    )
  }
}

export default Works

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    works: allFile(filter: {
      sourceInstanceName: {eq: "works"},
      extension: {eq: "md"}
    }, sort: {
      fields: childMarkdownRemark___frontmatter___date,
      order: DESC
    }) {
      edges {
        node {
          id
          name
          childMarkdownRemark {
            html
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              branch
              client
              project
              url
              tagline
              tags
              display
            }
          }
        }
      }
    }
    images: allFile(filter: {
      sourceInstanceName: {eq: "works"},
      childImageSharp: {
        id: {ne: null}
      }
    }) {
      edges {
        node {
          name
          childImageSharp {
            id
            fluid {
              src
              srcSet
              sizes
              base64
              aspectRatio
            }
          }
        }
      }
    }
  }
`
