import React from "react";

const ExternalSvg = props => (
  <svg width="48px" height="48px" viewBox="0 0 48 48" {...props}>
    <g stroke="none" strokeWidth={1} fill="#000" fillRule="evenodd">
      <path d="M21 7v4H5v32h32V27h4v20H1V7h20z" fillRule="nonzero" />
      <path d="M47 1v20h-4V7.828L30.343 20.485l-2.828-2.828L40.17 5H27V1h20z" />
    </g>
  </svg>
);

export default ExternalSvg;
